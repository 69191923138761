import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider, Drawer, Modal } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';

let index = 0;

export default class ModalDrawerBase {
  config: any;
  added: boolean;
  modal: any;
  id: number | string | null;
  type: 'modal' | 'drawer';
  loadDom: string;
  content: any;

  static singleModal: any = null;

  static init(config: any) {
    if (!this.singleModal) this.singleModal = new ModalDrawerBase(config);
  }

  static show(config?: any) {
    if (!this.singleModal) this.init(config);
    this.singleModal.show(config);
  }

  static hide() {
    this.singleModal && this.singleModal.hide();
  }

  constructor(config: any) {
    this.config = { ...config };
    this.added = false;
    this.modal = null;
    this.id = null;
    this.type = 'modal';
    this.loadDom = 'body';
  }

  add() {
    if (this.added || !this.id) {
      return;
    }
    this.added = true;

    const dom: any = document.createElement('div');
    dom.id = this.id;
    document.querySelector(this.loadDom)?.appendChild(dom);

    ReactDOM.render(
      <ParentModal
        type={this.type}
        onRef={(ref: any) => this.modal = ref}
        removeDom={this.removeDom}
      >
        {this.content}
      </ParentModal>,
      dom,
    );
  }

  show(config?: any) {
    this.id = `single_modal_${index}`;
    index = index + 1;
    this.add();
    this.modal.show(config || this.config);
  }

  hide() {
    this.modal && this.modal.hide();
  }

  removeDom = () => {
    if (!this.id) {
      return;
    }
    const dom = document.querySelector(`#${this.id}`);
    setTimeout(() => {
      if (dom) {
        const parent: any = dom.parentElement;
        parent && parent.removeChild(dom);
        index = index - 1;
      }
    }, 100);
  };
}

interface IProps {
  onRef: Function;
  removeDom: Function;
  type: 'modal' | 'drawer';
  children?: any;
}

// 父容器
class ParentModal extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ext: null,
      content: null,
      visible: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);

    this.confirm = this.confirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show({ content, ...ext }: any) {
    this.setState({ visible: true, content, ext });
  }

  hide() {
    this.setState({ visible: false });
    this.props?.removeDom();
  }

  confirm() {
    const { ext = {}, content }: any = this.state;
    ext.onOk && ext.onOk(content);
    this.hide();
  }

  handleCancel() {
    const { ext = {}, content }: any = this.state;
    ext.onCancel && ext.onCancel(content);
    this.hide();
  }

  render() {
    const { visible, content, ext }: any = this.state;
    const newExt = Object.assign({}, ext);
    delete newExt.onOk;
    delete newExt.onCancel;
    return (
      <ConfigProvider locale={zhCN}>
        {
          this.props.type === 'modal' ? (
            <Modal
              onOk={this.confirm}
              onCancel={this.handleCancel}
              open={visible}
              {...newExt}
              getContainer={document.querySelector(`#single_modal_${index - 1}`)}
            >
              {content}
            </Modal>
          ) : (
            <Drawer
              onClose={this.handleCancel}
              open={visible}
              {...newExt}
              getContainer={document.querySelector(`#single_modal_${index - 1}`)}
            >
              {content}
            </Drawer>
          )
        }
      </ConfigProvider>
    );
  }
}
