import React, { useEffect } from 'react';
import { Card, Form, Input, Button } from 'antd';
import { DAYU_LOGO } from '../../constants/common';
import { noticeError } from '@ug/utils';
import { rtaLogin } from '../../services/login';
import md5 from 'md5';
import './index.less';

const LoginPanel = () => {
  const [form]: any = Form.useForm();

  useEffect(() => {
  }, []);

  const handleRtaLogin = async (values: any) => {
    try {
      const rst = await rtaLogin({
        ...values,
        password: md5(values.password),
      });
      if (rst.msg) {
        noticeError(rst.msg);
      } else {
        window.location.href = '/console';
      }
    } catch (e) {
      noticeError('');
    }
  };

  return (
    <div className="portal-login-page" >
      <div className="banner">
        <div className="logo"><img src={DAYU_LOGO} /></div>
        <div className="menus">
          <a href="/">关于我们</a>
        </div>
      </div>
      <Card className='login-card'>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          onFinish={handleRtaLogin}
          onReset={() => form.resetFields()}
          form={form}
        >
          <Form.Item label="手机号码" name="phonenumber"
            rules={[
              { required: true, message: '手机号码不能为空' },
            ]}>
            <Input placeholder='请输入手机号码' />
          </Form.Item>

          <Form.Item
            label="初始化密码"
            name="password"
            rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password />
          </Form.Item>

          <Button type="primary" htmlType="submit">登录</Button>
          <Button htmlType="reset" style={{ marginLeft: 10 }}>重置</Button>
        </Form>
      </Card>
    </div >
  );
};

export default LoginPanel;
