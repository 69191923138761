import { IExtInfo } from "../types/common";

// 将信息拼接为html元素
export const infoToHtml = (extInfo: IExtInfo[]) => {
  return extInfo.map((info: IExtInfo) => {
    if (info.href) {
      return `<p>${info.label}: ${info.href}</p>`
    }
    return `<p>${info.label}: ${info.value}</p>`
  }).join('');
};
