import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Layout, Spin, Modal } from 'antd';
import {
  start,
  registerMicroApps,
  setDefaultMountApp,
  runAfterFirstMounted,
  addGlobalUncaughtErrorHandler,
} from 'qiankun';

import '@ug/guide'; // 因为指引组件不支持指定挂载元素，所以在portal内提前引入包内css样式
import Exception from '@ug/exception';
import { messageError, isDev, isPro, aegisReport } from '@ug/utils';
import { routes } from '@/configs';
import UserManager from '@/utils/UserManager';
import AuthRoute from '@/components/AuthRoute';
import LoginPanel from '../login';

import Header from './header';

const mapState = (state: any) => {
  const { productList, userInfo, configData } = state.common;
  return { productList, userInfo, configData };
};
const mapDispatch = (dispatch: any) => {
  const { getProductList, getUserInfo, getConfigData } = dispatch.common;
  return { getProductList, getUserInfo, getConfigData };
};

const portalStartTime = new Date().getTime();

const Main = (props: any) => {
  const { userInfo, productList, configData } = props;
  const [hasAuth, setHasAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [appPath, setAppPath] = useState([]);
  const [newMenusData, setNewMenusData]: any = useState([]);
  const defaultProd = localStorage.getItem('productid');

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.auths?.products?.length) {
      props.getProductList();
      props.getConfigData();
    }
  }, [userInfo]);

  useEffect(() => {
    if (configData?.menusData) {
      initMenu();
    }
  }, [configData]);

  const getUserInfo = async () => {
    try {
      const userResp = await props.getUserInfo();
      // 权限校验放在获取用户信息之后立即执行

      // 1: 校验产品权限
      const hasProductAuth = userResp.auths?.products?.length;

      // 2: 校验菜单权限
      const { pathname } = window.location;
      const hasMenuAuth = UserManager.checkAuth({
        auths: userResp.auths.menus,
        path: ['/', '/page'].includes(pathname) ? '/console' : pathname,
      });

      if (hasProductAuth && hasMenuAuth) {
        setHasAuth(true);
      }
    } catch (error: any) {
      aegisReport(`parentApp[dayurta-portal] getUserInfo error ${error.message}`);
    }
  };

  const initMenu = async () => {
    try {
      if (document.hidden !== undefined) {
        document.addEventListener('visibilitychange', () => {
          const realProd = localStorage.getItem('productid');

          if (defaultProd !== realProd) {
            Modal.info({
              content: '当前页面的所属产品与最新切换的不一致，系统将自动刷新当前页面',
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        });
      }

      const startTime = new Date().getTime();

      // 子应用路由
      const { appPath } = await getAppMateData();
      setAppPath(appPath);

      // 本地无缓存产品 或 本地已缓存产品不在已有权限的产品列表，则默认初始化第一个产品权限
      let productid = Number(window.localStorage.getItem('productid'));
      if (!productid || !userInfo.auths.products.includes(productid)) {
        [productid] = userInfo.auths.products;
        localStorage.setItem('productid', String(productid));
      }

      // 3: 根据权限过滤菜单项
      const newMenusData = UserManager.assembleMenus(configData?.menusData, userInfo?.auths?.menus);
      setNewMenusData([...newMenusData]);

      // 判断是否加载子应用
      if (!getIsLoadApp()) {
        setLoading(false);
      }

      const endTime = new Date().getTime();
      aegisReport(`parentApp[dayurta-portal] interface endTime - startTime：${endTime - startTime}ms`);
      // 启动子应用
      startQiankun();
    } catch (error: any) {
      messageError(error.message);
    }
  };

  const getIsLoadApp = () => {
    const { pathname } = window.location;
    if (pathname === '/') {
      return true;
    }
    let isLoadApp = false;
    appPath.forEach((path) => {
      if (pathname.startsWith(path)) {
        isLoadApp = true;
      }
    });
    return isLoadApp;
  };

  const getAppMateData = (): any => {
    const { appMateData = [] } = configData;

    let defaultActive = '/console';
    const newAppMateData: Array<any> = [];
    const appPath: Array<string> = [];

    appMateData.forEach((item: any) => {
      // 默认激活的子应用路由
      if (item.isDefault) {
        defaultActive = item.activeRule;
      }

      // 过滤禁用的子应用
      if (!item.isDisabled) {
        const { name, entry, container, activeRule } = item;
        newAppMateData.push({
          name,
          entry,
          container,
          activeRule,
          props: {
            userInfo,
            productList,
          },
        });
        appPath.push(activeRule);
      }
    });

    return {
      newAppMateData,
      defaultActive,
      appPath,
    };
  };

  const startQiankun = () => {
    // 子应用+默认激活路由
    const { newAppMateData, defaultActive } = getAppMateData();
    let beforeLoadTime = 0;
    let beforeMountTime = 0;
    let afterMountTime = 0;
    let afterUnmountTime = 0;
    // Step1 注册子应用
    registerMicroApps(newAppMateData, {
      beforeLoad: [
        async (app) => {
          beforeLoadTime = new Date().getTime();
          aegisReport(`subApp[${app.name}] beforeLoadTime - portalStartTime: ${beforeLoadTime - portalStartTime}ms`);
        },
      ],
      beforeMount: [
        async (app) => {
          beforeMountTime = new Date().getTime();
          aegisReport(`subApp[${app.name}] beforeMountTime - portalStartTime: ${beforeMountTime - portalStartTime}ms`);
        },
      ],
      afterMount: [async (app) => {
        afterMountTime = new Date().getTime();
        aegisReport(`subApp[${app.name}] afterMountTime - portalStartTime: ${afterMountTime - portalStartTime}ms`);
      }],
      afterUnmount: [
        async (app) => {
          afterUnmountTime = new Date().getTime();
          aegisReport(`subApp[${app.name}] afterUnmountTime - portalStartTime: ${afterUnmountTime - portalStartTime}ms`);
        },
      ],
    });

    // Step2 设置默认进入的子应用
    if (window.location.pathname === '/') {
      setDefaultMountApp(defaultActive);
    }

    // 添加全局的未捕获异常处理器
    addGlobalUncaughtErrorHandler((event: any) => {
      if (event?.message) {
        aegisReport(`parentApp[dayurta-portal] addGlobalUncaughtErrorHandler: ${event?.message}`);
      }
    });

    // 第一个微应用 mount 后需要调用的方法，比如开启一些监控或者埋点脚本
    runAfterFirstMounted(() => {
      // 处理loading状态
      setLoading(false);
    });

    // Step3 启动加载子应用
    start({
      prefetch: isDev() || isPro(),
      sandbox: {
        experimentalStyleIsolation: true,
      },
    });
  };

  if (!hasAuth) {
    return <LoginPanel />;
  }

  return (
    <div className="portal-wrapper">
      <Layout>
        <Header userInfo={userInfo} productList={productList} menusData={newMenusData}></Header>
        <Layout.Content className="portal-container" id="portal-container">
          <Spin spinning={loading} className="portal-spin">
            <div className="sub-app-container" id="sub-app-container"></div>
          </Spin>
          {
            userInfo.login && (
              <Switch>
                {routes.map((props, index) => <AuthRoute key={index} auths={userInfo?.auths?.menus} {...props} />)}
                {appPath?.length > 0 && <Route render={() => <Exception type="404" source="portal" appPath={appPath} />}></Route>}
              </Switch>
            )
          }
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default connect(mapState, mapDispatch)(Main);
