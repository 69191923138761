import { post } from "../utils/http";

// 获取文案列表
export const postOnCallOpenAPI = async (data = {}) => await post('/api/on-call/post-on-call-api', data);

// 上传图片
export const uploadOnCallImg = async (data: { file: any, key: string }) => {
    const { file, key } = data;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('key', key);
    formData.forEach((value, key) => {
        console.log(`${key}:`, value);
    });

    return await post('/api/market/ad-creative/post-creative-component-image', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}