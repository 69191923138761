// 工单类型 
export const ON_CALL_TYPE = {
  DEMAND: {
    value: 'demand',
    text: '运营类',
    desc: '使用咨询/服务请求/需求建议'
  },
  FAULT: {
    value: 'fault',
    text: '故障类',
    desc: '线上故障/线上BUG/异常事件'
  },
};
