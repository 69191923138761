// 本地环境
export const isLocal = () => {
  const { hostname } = window.location;
  return hostname === 'localhost';
};

// 测试环境
export const isDev = () => {
  const { hostname } = window.location;
  return ['test.qbug.woa.com', 'testrme.woa.com', 'testcreative.woa.com'].includes(hostname);
};

// 生产环境
export const isPro = () => {
  const { hostname } = window.location;
  return ['qbug.woa.com', 'rme.woa.com', 'iug.woa.com', 'creative.woa.com'].includes(hostname);
};

// 获取环境
export const getEnv = () => {
  if (isLocal()) {
    return 'LOCAL';
  }
  if (isDev()) {
    return 'DEV';
  }
  if (isPro()) {
    return 'PROD';
  }
  return 'LOCAL';
};

// 是否其他portal接入
export const isOtherPortal = (): boolean => {
  const { hostname } = window.location;
  return ['test.qbug.woa.com', 'qbug.woa.com'].includes(hostname);
}