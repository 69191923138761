import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { IProduct } from '@/types/common';

interface IProps {
  productList: IProduct[]
}

const Products = (props: IProps) => {
  const { productList } = props;
  const [defaultProduct, setDefaultProduct] = useState('');

  useEffect(() => {
    getDefaultProduct();
  }, []);

  const getDefaultProduct = async () => {
    const globalProduct = Number(window.localStorage.getItem('productid'));
    const filterCacheProduct = productList?.find(v => v.product_id === globalProduct);
    const defaultProduct = filterCacheProduct === undefined ? productList?.[0]?.product_id : globalProduct;
    setDefaultProduct(String(defaultProduct));
  };

  const handleChange = async (value: string) => {
    window.localStorage.setItem('productid', value);

    // 切换产品时，为了防止localStorage内的账号ID在新的产品下不存在，需要清除ls内account
    localStorage.removeItem('accountId');
    // 同时重置选择的tab为批量任务
    localStorage.setItem('apiTaskTab', 'batch');

    window.location.reload();
  };

  return (
    <div
      className="header-product">
      <Select
        showSearch
        popupClassName="product-select-list"
        value={defaultProduct}
        placeholder="选择一个产品"
        onChange={value => handleChange(value)}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        filterOption={(inputValue, option) => option?.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
      >
        {productList.map(({ product_id: id, product_name: name, icon_url: icon }) => (
          <Select.Option key={String(id)} value={String(id)} title={name}>
            <div className="product-select-item">
              {icon ? <img className="product-icon" src={icon} alt=''></img> : null} <span>{name}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default Products;
