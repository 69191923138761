import { Menu, Dropdown } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { MenuItem } from '@/types/common';

const { SubMenu } = Menu;

interface IProps extends RouteComponentProps {
  location: {
    pathname: string;
    hash: string;
    key: string;
    search: string;
    state: any
  };
  menus: MenuItem[];
};

const UGMenus = (props: IProps) => {
  const { pathname } = props.location;
  const { menus } = props;

  const renderMenuItem = (menu: MenuItem) => (
    <Menu.Item
      key={menu.path}
      className={pathname === menu.path ? 'ant-dropdown-menu-item-active' : ''}
    >
      {renderMenuItemDom(menu)}
    </Menu.Item>
  );

  const renderMenuItemDom = (menu: MenuItem) => {
    if (menu.disabled) {
      return <span style={{ cursor: 'not-allowed' }}>{menu.name}{renderMenuItemIcon(menu)}</span>;
    }
    if (!menu.disabled) {
      if (menu.targetBlank) {
        return <a href={menu.path} target="_blank" rel="noopener noreferrer">{menu.name}{renderMenuItemIcon(menu)}</a>;
      }
      return <Link to={menu.path}>{menu.name}{renderMenuItemIcon(menu)}</Link>;
    }
  };

  const renderMenuItemIcon = (menu: MenuItem) => (menu.icon && <img className="ant-dropdown-menu-icon ant-dropdown-menu-icon-custom" style={{ marginLeft: 5 }} src={menu.icon} alt=""></img>);

  const renderMenus = () => menus.map((menu: MenuItem, index: number) => {
    // 没有子菜单
    if (menu?.children?.length === 0) {
      return renderMenuItem(menu);
    }

    return (
      <SubMenu
        key={`SubMenu${index}`}
        title={
          <Dropdown
            placement="bottom"
            getPopupContainer={triggerNode => triggerNode.parentElement || document.body}
            overlay={
              <Menu>
                {
                  menu?.children?.map((cMenu, cIndex) => (
                    cMenu?.children?.length > 0
                      ? <Menu.ItemGroup className="ant-dropdown-menu-custom-group" key={`SubMenu${index}_${cIndex}`} title={
                        <div className="ant-dropdown-menu-custom-title">
                          <img className="ant-dropdown-menu-icon" src={cMenu.icon} alt="" style={{ marginRight: 5 }}></img>
                          <div className="ant-dropdown-menu-name">{cMenu.name}</div>
                        </div>
                      }>
                        {
                          cMenu?.children.map(ccMenu => (renderMenuItem(ccMenu)))
                        }
                      </Menu.ItemGroup>
                      : renderMenuItem(cMenu)
                  ))
                }
              </Menu>
            }>
            <span>
              {menu.name}
              <img
                className="ug-menu-arrow-img"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAETSURBVHgB7djLDYJAFIXhi3u7sgFtwAZ0bwXIHhuwAjuwASqwACuwAJyJTELIQHjN5eH/JTdGnGQ4J2AUEQAAAAAAAAAAAKBRnucHM5mZp5m9KJt6fyk2fpVG7SSK8OW9M+lpI/1tK+8TjRJsePNyrRz+SE9DCog9x4KWUBPeSmUKNmzlUgx2O3gue/Vbr+7Egpcw2/BOyBJmH94JUcJiwjtjlrC48M4YJSw2vDOkhMWHd/qUsJrwTpcSVhfeaSjhXFozWfhIFBRBEs9HNzNv8f+8vURR9JDAVAqwGkrwUQlvDfkz1EkR6NJiaawV3lIrwGpRQmrW3GXtar4YT/JPTOBd/nuqZB9rHQUAAAAAAABQ8QXhA4vexn+6UQAAAABJRU5ErkJggg=="
              />
            </span>
          </Dropdown>
        }>
        {
          menu?.children?.map(cMenu => (
            cMenu?.children?.length > 0
              ? cMenu?.children.map(ccMenu => (<Menu.Item style={{ display: 'none' }} key={ccMenu.path}></Menu.Item>))
              : <Menu.Item style={{ display: 'none' }} key={cMenu.path}></Menu.Item>
          ))
        }
      </SubMenu>
    );
  });

  return (
    <div className="header-menu">
      <Menu className="ug-menu" mode="horizontal" selectedKeys={[pathname]} defaultSelectedKeys={['/']}>
        {renderMenus()}
      </Menu>
    </div>
  );
};

export default withRouter(UGMenus);
