import React, { useState } from 'react';
import { Descriptions, Space, Button, message } from 'antd';

import { postOnCallOpenAPI } from './services/on-call';
import { IExtInfo } from './types/common';
import { infoToHtml } from './utils/data-format';

interface IProps {
  defaultTitle: string;  // 标题
  defaultMessage: string; // 错误信息(后台相关)
  extInfo?: IExtInfo[]; //  需要的额外信息
  onClose: () => void // 关闭弹窗
};

// onCall模板反馈弹窗
const OnCallDefaultModal = (props: IProps) => {
  const { extInfo, defaultMessage, defaultTitle } = props;
  const [loading, setLoading] = useState(false);
  const username = localStorage.getItem('username');

  const doReport = async () => {
    try {
      const params = {
        title: defaultTitle,
        detail: (extInfo ? infoToHtml(extInfo) : '') + `<p>错误信息： ${defaultMessage}</p>`,
      }

      setLoading(true);
      const res = await postOnCallOpenAPI(params);
      setLoading(false);

      if (res.code === 0) {
        message.success('反馈成功！');
        props.onClose();
        window.open(`https://oncall.woa.com/incident?id=${res.incident_id}`, '_blank');
      } else {
        message.error(`反馈失败！错误信息:${res.msg}`);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      message.error(`反馈失败！错误信息：${error}`);
    }
  };

  return (
    <>
      <Descriptions column={3}>
        <Descriptions.Item span={3} label="问题描述" className='word-break'>
          {defaultMessage}
        </Descriptions.Item>
        <Descriptions.Item label="反馈人">{username}</Descriptions.Item>
        {
          extInfo?.map((item) => {
            if (item.href) {
              return <Descriptions.Item key={item.label} label={item.label}>
                <a href={item.href} target='_blank'>{item.value}</a>
              </Descriptions.Item>
            }
            return <Descriptions.Item key={item.label} label={item.label}>{item.value}</Descriptions.Item>
          })
        }
      </Descriptions>
      <div className='flex flex-end'>
        <Space>
          <Button key="back" onClick={() => props.onClose()}>
            取消
          </Button>
          <Button key="submit" type="primary" loading={loading} onClick={doReport}>
            反馈
          </Button>
        </Space>
      </div>
    </>
  );
};
export default OnCallDefaultModal;
