import React, { useState, useEffect } from 'react';
import Guide from 'byte-guide';
import './index.less';

/**
 * 新手指引组件
 * api文档：https://github.com/bytedance/guide/blob/main/README.zh-CN.md
 */
const GuideWidget = (props: any) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    // 可以设置一个毫秒时间，延时展示引导层
    if (props.delay) {
      setTimeout(() => {
        setVisible(true)
      }, props.delay);
    } else {
      setVisible(props.visible);
    }
  }, []);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible])

  return (
    <Guide
      localKey={props.localKey}
      steps={props.steps}
      visible={visible}
      nextText="下一步"
      prevText="上一步"
      showPreviousBtn
      okText='我知道了'
      {...props}
    />
  )
};

export default GuideWidget;
