import axios, { AxiosRequestConfig, Method } from 'axios';
import { Modal, notification } from 'antd';

interface Response {
  code: number,
  message: string,
  data: any,
}

enum ErrorCode {
  EXCEPTION = -1,
  NO_ERROR = 0,
  NO_AUTH = 403,
}

const ErrorMessage = {
  [ErrorCode.EXCEPTION]: '系统异常',
  [ErrorCode.NO_AUTH]: '无系统权限',
};

const checkCode = (resp: Response) => {
  const { code = ErrorCode.EXCEPTION, message = ErrorMessage[ErrorCode.EXCEPTION], data = null } = resp;

  if (code !== ErrorCode.NO_ERROR) {
    throw new Error(message);
  }

  return data;
};

interface HttpRequest {
  checkFunc?: (resp: Response) => any,
  method?: Method,
  data?: Object,
  defaultValue?: any;
  [options: string]: any;
}

axios.defaults.withCredentials = true;

const addXHRHeaders = (headers: Object): Object => Object.assign(
  {
    productid: window.localStorage.getItem('productid'),
  },
  headers || {},
  {
    'X-Requested-With': 'XMLHttpRequest',
  },
);

const http = async (httpRequest: HttpRequest): Promise<any> => {
  const { checkFunc = checkCode, defaultValue, method = 'get', data, ...options } = httpRequest;
  const { hostname } = window.location;
  if (['test.qbug.woa.com'].includes(hostname)) {
    options.url = `https://testrme.woa.com${options.url}`;
  }
  if (['qbug.woa.com'].includes(hostname)) {
    options.url = `https://rme.woa.com${options.url}`;
  }
  try {
    options.headers = addXHRHeaders(options.headers);

    const request: AxiosRequestConfig = {
      method,
      ...options,
    };

    if (method === 'get') {
      request.params = data;
    } else {
      request.data = data;
    }

    const { data: response } = await axios(request);

    return checkFunc(response);
  } catch (error) {
    // ajax请求无法自动处理302实现重定向，需要加X-Requested-With，让智能网关返回401,手动跳转，详见下文
    // http://km.oa.com/group/35877/articles/show/370833?kmref=search&from_page=1&no=3
    if (error?.response?.status === 401) {
      return window.location.reload();
    }

    if (error?.message === 'canceled') {
      return;
    }

    if (options.errorDisplayMode === 'modal') {
      Modal.error({
        title: '错误',
        content: error?.message,
        okText: '确认'
      });
    } else {
      notification.error(error?.message);
    }
    return defaultValue;
  }
};

export const fileHttp = async (httpRequest: HttpRequest): Promise<any> => {
  const { checkFunc = checkCode, defaultValue, ...options } = httpRequest;
  const { hostname } = window.location;
  if (['test.qbug.woa.com'].includes(hostname)) {
    options.url = `https://testrme.woa.com${options.url}`;
  }
  if (['qbug.woa.com'].includes(hostname)) {
    options.url = `https://rme.woa.com${options.url}`;
  }

  try {
    const request: AxiosRequestConfig = {
      method: 'get',
      ...options,
    };

    const { data: response } = await axios(request);
    const url = window.URL.createObjectURL(response);
    const ele = document.createElement('a');
    ele.href = url;
    ele.download = options.filename;
    ele.click();
  } catch (error) {
    notification.error(error?.message);
  }
};

export default http;

export const get = (url: string, params?: Object, defaultValue?: any) => http({
  method: 'get',
  url,
  data: params,
  defaultValue,
});

export const post = (url: string, params: Object, defaultValue?: any, options = {}) => http({
  method: 'post',
  url,
  data: params,
  defaultValue,
  ...options
});
