import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Popover, Radio, Space, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { InfoCircleOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';

import { postOnCallOpenAPI, uploadOnCallImg } from './services/on-call';
import { ON_CALL_TYPE } from './constants/common-constant';
import { infoToHtml } from './utils/data-format';
import { IExtInfo } from './types/common';

interface IProps {
  defaultTitle: string;
  extInfo?: IExtInfo[];
  onClose: () => void;
}

// 用户自主上报
const OnCallCustomizeModal = (props: IProps) => {
  const { defaultTitle, extInfo } = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('pictures', fileList);
  }, [fileList])

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'].includes(file.type);
    if (!isJpgOrPng) {
      return message.error('请上传JPG/PNG格式的图片!');
    }

    if (file.size / 1024 / 1024 > 5) {
      return message.error('图片不能超过5MB');
    }

    try {
      // 发起请求，上传图片
      const res = await uploadOnCallImg({ file, key: 'onCall' });
      if (res.url) {
        const newFile: UploadFile<any> = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: res.url,
        };
        setFileList((prevList) => [...prevList, newFile]);
      }
      return false;
    } catch (error) {
      message.error(error?.message || error);
    }

    return false;
  };

  const onRemove = (file: UploadFile) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
    return true;
  }

  const uploadProps: UploadProps = {
    maxCount: 3,
    fileList,
    beforeUpload,
    onRemove,
    capture: true,
  };

  const onFinish = async () => {
    const data = await form.validateFields();
    try {
      const title = defaultTitle + data.title;
      const imgList = fileList.length
        ? fileList.map(file => `<img src='${file.url}' style='max-width: 300px;'/><br>`).join('')
        : undefined;
      const detail = data.detail + '<br/>'
        + (extInfo ? infoToHtml(extInfo) : '') + '<br/>'
        + (imgList || '');
      const params = {
        title,
        detail,
        tag: data.tag
      }

      setLoading(true);
      const res = await postOnCallOpenAPI(params);
      setLoading(false);
      if (res.code === 0) {
        message.success('反馈成功！');
        props.onClose();
        window.open(`https://oncall.woa.com/incident?id=${res.incident_id}`, '_blank');
      } else {
        message.error(`反馈失败！错误信息:${res.msg}`);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message);
    }
  }

  return <>
    <Form
      form={form}
      onFinish={onFinish}
      layout="horizontal"
      labelCol={{ span: 3 }}
      labelAlign="left"
    >
      <Form.Item
        label="问题类型"
        name='tag'
        initialValue='demand'
        rules={[
          { required: true, message: '请选择问题类型' },
        ]}>
        <Radio.Group buttonStyle="solid" >
          {
            Object.values(ON_CALL_TYPE).map(item => <Radio.Button
              key={item.value}
              value={item.value}
            >{item.text}&nbsp;
              <Popover
                content={<div>{item.desc}</div>}>
                <QuestionCircleOutlined />
              </Popover>
            </Radio.Button>)
          }
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="标题"
        name='title'
        rules={[
          { required: true, message: '标题不能为空' },
        ]}>
        <Input
          placeholder="请输入问题概述，以助平台快速识别处理"
        />
      </Form.Item>
      <Form.Item
        label="问题描述"
        name='detail'
        rules={[
          { required: true, message: '问题描述不能为空' },
        ]}
      >
        <Input.TextArea
          autoSize={{
            minRows: 4,
            maxRows: 8,
          }}
          placeholder="请输入问题的详细描述，例如产品名称，批量任务ID，问题复现路径等"
        />
      </Form.Item>

      <Form.Item
        label={<div>
          <Popover content={<div>
            <p>最多可上传3张图片</p>
            <p>图片大小不可超过5MB</p>
          </div>}>图片上传 <InfoCircleOutlined /></Popover>
        </div>
        }
        name="pictures"
        rules={[
          { required: true, message: '请至少上传一张图片' },
        ]}
      >
        <Upload
          {...uploadProps}
        >
          <Button icon={<UploadOutlined />}>点击上传</Button>
        </Upload>
        <span className='fs12 color-gray'>请上传清晰全屏截图</span>
      </Form.Item>
      <Form.Item noStyle>
        <div className='flex flex-end'>
          <Space>
            <Button key="back" onClick={() => props.onClose()}>
              取消
            </Button>
            <Button key="submit" type="primary" loading={loading} onClick={onFinish}>
              反馈
            </Button>
          </Space>
        </div>
      </Form.Item>
    </Form>
  </>
}
export default OnCallCustomizeModal;