
import { notification, message, Button } from 'antd';
import React from 'react';

import { SingleModal } from '@ug/app-modal';
import { OnCallCustomizeModal } from '@ug/on-call-modal';

import { aegisReport } from './tool';

export function noticeSuccess(message: string) {
  notification.success({
    message,
  });
};

export function noticeError(message: string) {
  const showFeedbackModal = (message: string) => {
    const modal = new SingleModal({
      loadDom: '#root-portal',
      title: '问题反馈',
      width: 1100,
      centered: true,
      content: React.createElement(
        React.Fragment,
        null,
        React.createElement(OnCallCustomizeModal, {
          onClose: () => modal.hide(),
          defaultTitle: '【后台报错】',
          extInfo: [{ label: '错误信息：', value: message }]
        })
      ),
      maskClosable: true,
      closable: true,
      footer: null,
    });

    modal.show();
  };

  const btn = React.createElement(
    Button,
    {
      type: 'primary',
      size: 'small',
      onClick: () => showFeedbackModal(message)
    },
    '问题反馈'
  );

  notification.error({
    message,
    btn,
  });
  message && aegisReport(message);
};

export function noticeWarning(message: string) {
  notification.warning({
    message,
  });
  message && aegisReport(message);
};

export function noticeInfo(message: string) {
  notification.info({
    message,
  });
};

export function messageSuccess(msg: string) {
  message.success(msg);
};

export function messageError(msg: string) {
  message.error(msg);
  msg && aegisReport(msg);
};

export function messageWarning(msg: string) {
  message.warning(msg);
  msg && aegisReport(msg);
};

export function messageLoading(msg: string) {
  message.loading(msg);
};
