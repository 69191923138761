

import { groupBy, isEqual, some } from 'lodash';
import { REGEXP } from './constant';
import { getLocalStorageObject, setLocalStorageObject } from './cookie';
import { isLocal } from './env';

/**
  * 数据类型判断方法
  * ''              [object String] - return 'string'
  * 1               [object Number] - return 'number'
  * []              [object Array] - return 'array'
  * true            [object Boolean] - return 'boolean'
  * undefined       [object Undefined] - return 'undefined'
  * null            [object Null] - return 'null'
  * new Function()  [object Function] - return 'function'
  * new Date()      [object Date] - return date
  * new RegExp()    [object RegExp] - return 'regexp'
  * new Error()     [object Error] - return 'error'
  */
export const typeOf = (param: any) => Object.prototype.toString.call(param).match(/\s+(\w+)/)?.[1].toLowerCase();

export const pattern = (type: string) => ({
  pattern: new RegExp(REGEXP[type].value),
  message: REGEXP[type].text,
});

export const checkEmptyStr = (rule: any, value: string) => new Promise<void>((resolve, reject) => {
  console.log('checkEmptyStr rule', rule)
  if (/\s+/.test(value)) {
    reject('不能含有空字符串');
  } else {
    resolve();
  }
});

export const checkNoChineseCommaSemicolon = (rule: any, value: string) => new Promise<void>((resolve, reject) => {
  console.log('checkNoChineseCommaSemicolon rule', rule, value)
  if (value && (value.indexOf('，') !== -1 || value.indexOf('；') !== -1)) {
    reject('不能含有中文逗号或中文分号');
  } else {
    resolve();
  }
});

export const checkPhoneNumber = (_rule: any, value: string) => new Promise<void>((resolve, reject) => {
  if (!/^1\d{10}$/.test(value)) {
    reject('手机号码验证异常');
  } else {
    resolve();
  }
});

export const checkJSON = (rule: any, value: string) => new Promise<void>((resolve, reject) => {
  console.log('checkJSON rule', rule, value)
  if (!value) {
    resolve();
  }
  if (!parseJSON(value)) {
    reject('JSON数据格式错误！');
  } else {
    resolve();
  }
});

// 校验下拉组件多选是否超出最大个数
export const checkSelectMaxCount = (rule: any, value: any, max: number) => new Promise<void>((resolve, reject) => {
  console.log('checkSelectMaxCount rule', rule, value)
  if (!value) {
    resolve();
  }

  if (value.length > max) {
    reject();
  }

  resolve();
});

export const parseJSON = (str: any) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const urlQuery = (key: string, url?: string) => {
  const reg = new RegExp(`(^|&|\\?|#|&amp;)${key}=([^&#]*)(&|$|#)`, '');
  const newUrl = url || document.location.href;
  if (typeof newUrl === 'undefined') {
    return;
  }
  const match = newUrl.match(reg);
  if (match) {
    return match[2];
  }
  return '';
};

export const thousandNumber = (value: number | string = ''): string => {
  const newValue = String(value);
  return (!newValue ? newValue : `${newValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
};

export const getConstantItem = (constantObj: any, value: number | string | undefined): any => {
  if (!constantObj || value === undefined) {  // value可能为0
    return {}
  }
  return Object.values(constantObj).find((item: any) => item.value === value) || {};
}

export const getConstantItemEn = (constantObj: any, value: number | string): any => {
  return (Object.values(constantObj).find((item: any) => item.valueEn === value) || {});
}

export const getConstantValueEn = (constantObj: any, value: number | string): any => {
  return getConstantItemEn(constantObj, value).text;
}

export const getConstantLabel = (constantObj: any, value: number | string): any => {
  return getConstantItem(constantObj, value).label;
}

export function getConstantText(constantObj: any, value: number | string) {
  return getConstantItem(constantObj, value).text;
}

export function getUpgradedConstant(constantObj: any, value: number | string): any {
  if (!constantObj || !value) {  // value可能为0
    return {}
  }
  return Object.values(constantObj).find((item: any) => item.key === value) || {};
}

export const changeStringToArray = (str = '') => String(str).split(',')
  .filter(item => item);

// 表格分页
export const handleTableChange = (callback: Function, pagination: any, filters: any, sorter: any, extra: any) => {

  const obj: any = {
    page: pagination.current,
    page_size: pagination.pageSize,
    ...extra,
  };

  // 删除分页方法扩展属性值 currentDataSource + action
  delete obj.currentDataSource;
  delete obj.action;

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      obj[key] = value.join(',');
    } else {
      obj[key] = undefined;
    }
  });
  if (sorter && sorter.order) {
    obj.sorter = `${sorter.field},${sorter.order}`;
  } else {
    obj.sorter = undefined;
  }
  callback(obj);
};

export const getPaginationProps = (props: any) => ({
  showQuickJumper: true,
  showSizeChanger: true,
  showTotal: (total: number) => `共计 ${total} 条数据`,
  ...props,
});

// 判断是否是代理账号，32位hash 数字+大写字母
export const isProxyAccount = (user: string) => {
  return /^[A-Z0-9]{32}$/g.test(user);
}

export const initLS = (key: string, data: any, lsVersion?: string) => {
  // 如果传入了一个ls的版本，且与当前ls内版本不同，则也需要设置数据
  if (lsVersion && lsVersion !== getLocalStorageObject(`${key}_ver`)) {
    setLocalStorageObject(key, data);
    setLocalStorageObject(`${key}_ver`, lsVersion);
  }

  if (!getLocalStorageObject(key)) {
    setLocalStorageObject(key, data);
  }
};

// 根据模块ID从媒体列表找返回支持的媒体
export const getMediaByModule = (mediaList: any, moduleId?: 'mkt' | 'rta' | 'quantify' | 'attribution') => {
  if (!moduleId) {
    return mediaList;
  }

  const media: any = {};
  Object.keys(mediaList).forEach((key: string) => {
    if (mediaList[key]?.fe_modules
      && mediaList[key]?.fe_modules?.split(',')?.includes(moduleId)) {
      media[key] = mediaList[key];
    }
  });
  return media;
}

export const scrollToAnchor = (anchorName: string | undefined) => {
  if (anchorName) {
    // 找到锚点
    const anchorElement: any = document.getElementById(anchorName);
    // 如果对应id的锚点存在，就跳转到锚点
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }
};

export const isEdit = (operations: any) => {
  if (isLocal()) {
    return true;
  }
  const productId = getLocalStorageObject('productid');
  return operations?.[productId]?.includes('editall') || false
}

// 账号列表按拉新拉活格式化
export const formatAccountTreeSelect = (list: any, scene_type: any) => {
  const accountListTree: any = [];
  const accountGroupByScene = groupBy(list, 'scene_type');

  Object.keys(accountGroupByScene).forEach((sceneType: any) => {
    const sceneItem: any = Object.values(scene_type).find((item: any) => item.value === Number(sceneType)) || {};
    accountListTree.push({
      value: sceneType,
      title: sceneItem.text,
      children: (accountGroupByScene[sceneType] || []).map((item: any) => ({
        value: item.account_id,
        title: `${item.account_id}-${item.operate_mode ? item.operate_mode + '-' : ''}${item.account_name}`,
      })),
    });
  });

  return accountListTree;
}

// 校验图片尺寸与参数是否一致
export const checkImageWH = (file: Blob, width: number, height: number): any => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
    const img = document.createElement('img');
    img.src = reader.result as string;
    img.onload = () => {
      if (img.naturalWidth !== width || img.naturalHeight !== height) { // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
        reject(`图片尺寸错误，要求尺寸${width}*${height}，实际尺寸${img.naturalWidth}*${img.naturalHeight}，请重新上传`);
      } else {
        resolve(true);
      }
    };
  };
});

interface IMacro {
  id?: number;
  macro: string;
  macro_name: string;
  macro_for?: string;
  comment?: string;
}

export const transMacro = (str: string, macroList: Array<IMacro>) => {
  const macroReg = /_{2}([a-zA-Z0-9]+_)+_/g;
  const dynamicDataMacroReg = /__MKT_DYNAMIC_([A-Z0-9]+_)+_/g;  // 匹配动态数据包的宏

  if (str) {
    // 1.删除encode宏
    const cullStr = str
      .replaceAll('__EE__.', '')
      .replaceAll('__NO_E__.', '')
      .replaceAll(dynamicDataMacroReg, '{动态数据包}');

    // 2.匹配属于宏的字符，并翻译为中文
    const arr = cullStr.match?.(macroReg) || [];
    const matchArr = arr.map((macro: string) => {
      // 从宏列表内找到当前匹配宏的信息，转为中文名。注意macro是删除了encode宏的，比较时对宏列表的项也要去除encode宏进行比较
      const findName = macroList.find(it => it.macro
        .replaceAll('__EE__.', '')
        .replaceAll('__NO_E__.', '') === macro)?.macro_name || '';
      return `{${findName}}`;
    });

    // 3.匹配不属于宏的字符，这里简单把所有的宏替换为两个空格(因为宏里面绝对不可能出现空格)，
    // 然后按空格split即可得到所有不属于宏的字符数组
    let noMacroStr = cullStr;
    arr.forEach((macro: string) => {
      noMacroStr = noMacroStr.replace(macro, '  ');
    });
    const noMatchArr = noMacroStr.split('  ');

    // 交替合并两数组即可得到最终拼接好的中文的规则
    matchArr.forEach((item, index) => noMatchArr.splice(1 * (index + 1) + index, 0, item));
    return noMatchArr?.join('');
  }
  return '';
};

// 数据上报
export const aegisReport = (message: string) => {
  (window as any).AEGIS?.report?.(message)
}

// 判断对象数组是否包含特定对象
export const isObjIncluded = (agesArray: any[], target: any) => some(agesArray, obj => isEqual(obj, target));