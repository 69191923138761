interface InitAuth {
  auths: string[];
  path: string;
}

const UserManager = {

  checkAuth(params: InitAuth) {
    const { auths, path } = params;
    return this.checkAuthByPath(auths, path);
  },

  // 根据path
  checkAuthByPath(auths: string[], path: string) {
    if (!auths) return false;
    return auths.find(u => u === path || new RegExp(u).test(path));
  },
  assembleMenus(menusData: any, menusAuth: any) {
    if (!menusAuth || menusAuth?.length === 0) {
      return [];
    }
    const newMenusData = [];
    for (let i = 0; i < menusData?.length; i++) {
      const item = menusData[i];
      const isAuth = menusAuth.find((u: any) => u === (item.path || item.name) || new RegExp(u).test(item.path));

      if (isAuth) {
        if (item.children && item.children.length > 0) {
          item.children = this.assembleMenus(item.children, menusAuth);
        }
        newMenusData.push(item);
      } else {
        // eslint-disable-next-line no-plusplus
        menusData.splice(i--, 1);
      }
    }
    return newMenusData;
  },
};

export default UserManager;
