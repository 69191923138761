import { parseJSON } from "./tool";

// 获取localStorage
export const getObj = (key: string) => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return {};
};

// 设置localStorage
export const saveObj = (key: string, obj: any) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const getLocalStorageObject = (key: string | undefined) => {
  try {
    const data = localStorage.getItem(`${key}`) || '';
    if (!data) {
      return undefined;
    }
    return parseJSON(data);
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

export const setLocalStorageObject = (key: string, value: any) => {
  try {
    localStorage.setItem(`${key}`, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};

// 设置cookie
export const setCookie = (name: string, value: string) => {
  const days = 30;
  const exp = new Date();
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
  const domain = window.location.hostname;
  document.cookie = `${name}=${escape(
    value
  )};expires=${exp.toUTCString()};domain=${domain};path=/`;
};

// 读取cookies
export const getCookie = (name: string) => {
  let arr;
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);

  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  return null;
};

// 删除cookies
export const delCookie = (name: string) => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval !== null) {
    document.cookie = `${name}=${cval};expires=${exp.toUTCString()}`;
  }
};
