// 异步加载组件
import Loadable from 'react-loadable';
import { Spin } from 'antd';

export default (function (loader) {
  // eslint-disable-next-line prefer-rest-params
  const config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return Loadable(Object.assign({
    loader,
    loading() {
      return (<Spin style={{ minHeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></Spin>);
    },
    delay: 1000,
  }, config));
});
