// 模拟数据
const configData = {
  // 子应用初始化
  appMateData: [
    {
      name: 'app-dayurta-console',
      entry: 'http://localhost:9001/',
      container: '#sub-app-container',
      activeRule: '/console',
      isDefault: false,
      isDisabled: false,
    },
    {
      name: 'app-dayurta-rta',
      entry: 'http://localhost:9010/',
      container: '#sub-app-container',
      activeRule: '/rta',
      isDefault: false,
      isDisabled: false,
    },
    {
      name: 'app-dayurta-account',
      entry: 'http://localhost:9011/',
      container: '#sub-app-container',
      activeRule: '/assets/account',
      isDefault: false,
      isDisabled: false,
    },
  ],
  // 菜单数据
  menusData: [
    {
      name: '总览',
      path: '/console',
      children: [],
    },
    {
      name: 'RTA管理',
      path: '/rta/strategy',
      children: [],
    },
    {
      name: '账号管理',
      path: '/assets/account',
      children: [],
    },
    {
      name: '对账中心',
      path: '/console/reconciliation',
      children: [],
    },
  ],
};
export default configData;
