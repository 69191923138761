/**
 * 用户卡片
 */
import { Avatar } from 'antd';
import { IUser } from '@/types/common';

interface IProps {
  userInfo: IUser;
};

const UserCard = (props: IProps) => {
  const { userInfo } = props;

  return (
    <div className="usercard">
      <div className="card-top">
        <Avatar className="avatar" src="https://smpimg-1258344701.file.myqcloud.com/smpreal/SMP_20241023_7579082.jpg" />
        <div className="user-info">
          <p className="username">{userInfo.nickname || userInfo.username}</p>
        </div>
      </div>
      <div className="card-bottom"><a className="logout" href={decodeURIComponent(userInfo.signOutUrl)}>退出登录</a></div>
    </div>
  );
};

export default UserCard;
