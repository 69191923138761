import ModalDrawerBase from './modal-drawer-base';

export default class SingleModal extends ModalDrawerBase {
  type: 'modal' | 'drawer';
  loadDom: string;

  constructor(props: any) {
    super(props);
    this.type = 'modal';
    this.loadDom = props.loadDom || 'body';
  }
}
