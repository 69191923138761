import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { init } from '@rematch/core';
import Aegis from '@tencent/aegis-web-sdk';
import { isDev, isPro } from '@ug/utils';

import * as models from '@/models';
import zhCN from 'antd/lib/locale-provider/zh_CN';

import Main from '@/pages/main';
import '@/assets/less/index.less';

let root: any = null;

renderReactApp();

function renderReactApp() {
  const history = createBrowserHistory();
  const middleware = routerMiddleware(history);
  const store = init({
    models,
    plugins: [
    ],
    redux: {
      reducers: {
        router: routerReducer,
      },
      middlewares: [middleware],
    },
  });

  const App = () => (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          locale={zhCN}
          input={{ autoComplete: 'off' }}
        >
          <Switch>
            <Route path="/" component={Main} />
          </Switch>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );

  if (isDev() || isPro()) {
    (window as any).AEGIS = new Aegis({
      id: isPro() ? 'TKTZOOsilmMTihCOCo' : 'TKTZOOsiizhwdmsuOC',  // 项目ID
      uin: window.localStorage.getItem('username') || '',         // 用户唯一ID
      reportApiSpeed: true,   // 开启接口测速
      reportAssetSpeed: true, // 开启静态资源测速
      spa: true,              // 当前页面是否是单页应用？true的话将会监听hashchange及history api，在页面跳转时进行pv上报
      websocketHack: true,    // 是否开启websocket监控
      api: {
        apiDetail: true,      // 上报请求参数和返回值
        // 上报接口返回code!==0的日志
        retCodeHandler(data: any) {
          let newData = data;
          try {
            newData = JSON.parse(newData);
          } catch (e: any) {
          }

          return {
            isErr: newData.code !== 0,
            code: newData.code,
          };
        },
      },
      beforeReport(log: any) {
        // 屏蔽页面重绘次数限制上报
        if (Number(log.level) === 4 && log.msg && log.msg.indexOf('ResizeObserver loop limit exceeded') > -1) {
          return false;
        }
      },
    });
  }

  const container = document.getElementById('app-dayurta-portal') as Element | DocumentFragment;
  root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(<App />);
}
