import React from 'react';
import { Layout, Popover, Avatar } from 'antd';
import { DAYU_LOGO } from '@/constants/common';
import { IProduct, IUser, MenuItem } from '@/types/common';

import UGMenu from './ug-menu';
import Products from './products';
import UserCard from './usercard';

interface IProps {
  userInfo: IUser;
  productList: IProduct[];
  menusData: MenuItem[];
};
const Header = (props: IProps) => {
  const { userInfo, productList, menusData } = props;

  return (
    <Layout.Header className="portal-header">
      <div className="header-left">
        <a href="/" className="header-logo">
          <img src={DAYU_LOGO} alt="logo"></img>
        </a>
        {productList?.length > 0 && (
          <Products productList={productList}></Products>
        )}
        {menusData?.length > 0 && <UGMenu menus={menusData}></UGMenu>}
      </div>
      <div className="header-right">
        <div className="header-helper">
          {userInfo.login && (
            <div className="helper-avatar" id="helper-avatar">
              <Popover
                overlayClassName="helper-popover"
                content={<UserCard userInfo={userInfo}></UserCard>}
                trigger="hover"
                placement="bottomRight"
                getPopupContainer={triggerNode => triggerNode.parentElement || document.body
                }
              >
                <span onClick={e => e.preventDefault()}>
                  <Avatar
                    className="avatar-img"
                    src="https://smpimg-1258344701.file.myqcloud.com/smpreal/SMP_20241023_7579082.jpg"
                  ></Avatar>
                </span>
              </Popover>
            </div>
          )}
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
