import AsyncCom from '@/components/AsyncCom';

const ROUTERS = [
  {
    path: '/exception/403',
    checkAuth: false,
    component: AsyncCom(() => import('@/pages/exception/403')),
  },
  {
    path: '/exception/404',
    checkAuth: false,
    component: AsyncCom(() => import('@/pages/exception/404')),
  },
  {
    path: '/exception/500',
    checkAuth: false,
    component: AsyncCom(() => import('@/pages/exception/500')),
  },
];
export default ROUTERS;
