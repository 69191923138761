
declare interface IConstantVT {
  [key: string]: {
    value: number | string | any,
    text: string,
    unit?: string,
    symbol?: string
  }
};

export const REGEXP: IConstantVT = {
  NUM: {
    value: /^\d+(\.\d+)?$/,
    text: '只能输入数字',
  },
  INTEGER: {
    value: /^[1-9][0-9]*$/,
    text: '只能输入大于零的正整数',
  },
  EMPTY_STR: {
    value: /\s+/,
    text: '不能含有空字符串',
  },
};