import { createModel } from '@rematch/core';
import { get, isLocal, getProductList } from '@ug/utils';
import { configData } from '@/configs';

export default createModel()({
  state: {
    productList: [],
    userInfo: {},
    configData: {},
    helpDrawerStatus: false,
    helpData: [],
  },
  reducers: {
    setState: (state: any, payload: any) => ({ ...state, ...payload }),
  },
  effects: {
    async getProductList(data = {}) {
      const rst = await getProductList(data);
      if (rst?.length > 0) {
        this.setState({
          productList: rst,
        });
        return {
          productList: rst || [],
        };
      }
      return [];
    },
    async getUserInfo(data = {}) {
      const rst = await get('/api/market/login/get-rta-login-user', data);
      if (rst) {
        this.setState({
          userInfo: rst, // 本地使用mock数据
        });
      }
      return rst || {};
    },
    async getConfigData(data = {}) {
      const rst = await get('/api/common/get-config-data', data);
      if (rst) {
        this.setState({
          configData: isLocal() ? configData : (rst || {}), // 本地使用mock数据
        });
      }
    },
  },
});
